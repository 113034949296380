import { getStore } from "@/store"
import { isEmpty, includes } from "lodash-es"
import { ROLE_PERMISSIONS } from "@/shared/variables/UserPermissionVariables"
import routes from "@/router/routes"
import { EventBus } from "@/plugins/event-bus"
import environment from "@/shared/environment"
import { createRouter, createWebHistory } from "vue-router"
import { inject } from "vue"

const Router = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    EventBus,
})

let appReference = null

export function setAppInstance(app) {
    appReference = app
}

export function configureRouter() {
    const store = getStore()

    const originalPush = Router.push
    Router.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) {
            return originalPush.call(this, location, onResolve, onReject)
        }
        return originalPush.call(this, location).catch((error) => {
            if (error.name !== "NavigationDuplicated") {
                return Promise.reject(error)
            }
        })
    }

    Router.beforeEach(async (to, from, next) => {
        const mixPanel = inject("mixpanel")

        // Isso aqui deverá ser migrado para inject local em cada componente que utilizar o mixpanel
        appReference.config.globalProperties.$mixpanel = mixPanel

        if (to.meta.isPublic) {
            /* Pula validação de token caso seja público */
            return next()
        } else if (!store.getters["auth/isLogged"]) {
            /* -- FLUXO DE ERRO: Não logado */
            /* Tenta fazer o login se não esitver logado e refaz a rota, ou então redireciona para a tela de login*/
            try {
                await store.dispatch("auth/authWithCookies")
                return next(to)
            } catch (err) {
                const referer = !["redirect-notfound", "notfound"].includes(to.name) ? to.path : undefined

                return next({ name: "login.auth", query: { referer, error: err.message } })
            }
        }
        /* -- FLUXO NORMAL */
        const {
            id,
            acl,
            account: { name: accountName },
            email,
            name = "",
        } = store.getters["auth/currentUser"]

        /* Identifica o usuário atual no Mixpanel */
        if (mixPanel.get_distinct_id() !== id) {
            mixPanel.identify(id)
            mixPanel.people.set({
                name,
                account: accountName,
                $email: email,
            })
        }

        /** Funcionalidade alpha é disponivel apenas para ambientes de desenvolvimento */
        const alphaIsNotVisible = to.meta.alphaFunctionality && !environment.APP_ALPHA

        if (alphaIsNotVisible) {
            return next({ name: "notfound", query: { errorId: 403 } })
        }

        /* Caso não tenha os customConfigs, carrega */
        if (isEmpty(store.getters["customConfig/get"])) {
            await store.dispatch("customConfig/fetch")
        }

        /* Valida permissão de ir para a rota */
        if (to.meta.accessPermission) {
            /* Redireciona caso o usuário não tenha permissão */
            if (!includes(ROLE_PERMISSIONS[acl], to.meta.accessPermission)) {
                return next({ name: "notfound", query: { errorId: 403 } })
            }
        }

        /* Desloga usuário caso o token seja cancelado */
        if (from.name !== "login.auth") {
            mixPanel.people.set({
                status: "Offline",
            })
        } else {
            mixPanel.people.set({
                status: "Online",
            })
        }

        /* Redireciona para a rota */
        next()
    })
}

export default Router
